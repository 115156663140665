const sortSuppliersByCategory = (suppliers) => {
  let groupedList = {};
  suppliers.forEach((item, index) => {
    if (!groupedList[item.supplierCategory]) {
      groupedList[item.supplierCategory] = [];
      groupedList[item.supplierCategory].push(item);
      return;
    }
    groupedList[item.supplierCategory].push(item);
  });
  console.log(groupedList);
  return groupedList;
};

const filterListBySearchValue = (list, value) => {
  let searchedList = [...list];
  searchedList = searchedList.filter((item) =>
    item.supplierName.toLowerCase().includes(value.toLowerCase())
  );
  return searchedList;
};

const searchInOtherCategories = (search, suppliersList) => {
  let searchedList = [];
  let newCategory = "";
  Object.entries(suppliersList).find(([key, value]) => {
    searchedList = value.filter((item) =>
      item.supplierName.toLowerCase().includes(search.toLowerCase())
    );

    if (searchedList.length > 0) {
      newCategory = key;
      return true;
    }
  });

  return {
    category: newCategory,
    suppliers: searchedList,
  };
};

let Utils = {
  sortSuppliersByCategory,
  filterListBySearchValue,
  searchInOtherCategories,
};

export default Utils;
