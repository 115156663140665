// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App-module--1WUZ4 {\n  display: grid;\n}\n\n.App-module--1WUZ4.App-module--35CVE {\n  gap: 32px;\n}\n\n.App-module--1WUZ4.App-module--1et82 {\n  padding: 80px 0;\n  background: #f6f7f9;\n}\n\n@media (max-width: 767px) {\n  .App-module--1WUZ4.App-module--1et82 {\n    padding: 40px 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".app {\n  display: grid;\n}\n\n.app.energy {\n  gap: 32px;\n}\n\n.app.webflow-container {\n  padding: 80px 0;\n  background: #f6f7f9;\n}\n\n@media (max-width: 767px) {\n  .app.webflow-container {\n    padding: 40px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "App-module--1WUZ4",
	"energy": "App-module--35CVE",
	"webflow-container": "App-module--1et82"
};
export default ___CSS_LOADER_EXPORT___;
